import consumer from "./consumer"

$(document).on('turbolinks:load', function(){
  if($('#deck').data('room_id') != null) {
    const chatChannel = consumer.subscriptions.create({ channel: 'RoomChannel', room: $('#deck').data('room_id') }, {

      connected() {
        console.log("Connect!");
      },

      disconnected() {
        console.log("Disconnect!");
      },

      // 現在のユーザID取得
      getCurrentUserId: function () {
        return $('#deck').data('user_id') - 0;
      },
      // 現在のルームID取得
      getCurrentRoomId: function () {
        return $('#deck').data('room_id') - 0;
      },

      // broadcastされた際に飛ぶ関数
      received(data) {

        // 部屋に入ってきたユーザーを表示する
        if(data.room != null) {
          console.log("ユーザーが部屋に入ってきました。");

          let node = $(`<div class="user-name user_name" data-user_id="${data.user.id}"/>`)
          node.text(data.user.username);
          $('#user_box').append(node);

          // 参加残り人数を変化させる
          let text    = $('#remain').text();
          let remain  = Number(text.match(/\d/)) - 1;
          // console.log("残り数：" + remain);
          $('#remain').html('参加可能な残りユーザー数：' + remain);
        }

        // roomから退出した時の処理
        if(data.exit != null){
          // 待機画面から退室の際
          if(data.flag == 0){
            // 参加ユーザー名を削除
            $($(`.user_name[data-user_id=${data.user.id}]`)).remove();
            // 参加残り人数を変化させる
            let text    = $('#remain').text();
            let remain  = Number(text.match(/\d/)) + 1;
            $('#remain').html('参加可能な残りユーザー数：' + remain);
          }

          // プレイ画面から退室の際
          if(data.flag == 1){
            // 全員リロード
            if(data.exit_player_order != Number($("#js-hand").attr("data-play_order"))){
              if (!chatChannel.isWatch()) {
                location.reload();
              }
              else {
                chatChannel.deleteWatchPlayer(data.user.id);
                chatChannel.logWatch(`<span class="alert-thing">${data.user.username}さんが退室しました</span>`);
              }
            }
          }
        }


        // 一人がゲーム開始ボタンを押した際の全員のページ遷移の処理
        if(data.game != null){
          if(chatChannel.getCurrentRoomId() == data.game.id){
            // 観戦者以外は遷移
            if (chatChannel.getCurrentUserId() != data.watch_user_id) {
              document.location.assign('/rooms/' + data.game.id + '/game');
            }
          }
        }

        // ボタンを押した側と押された側でメソッドを分ける
        if(data.playing_user_name != null) {
          let playOrder = $("#js-hand").data("play_order");
        }

        // カードを引いた時の処理
        if(data.draw_card != null){
          // デッキからカードが引かれた時の処理
          if(data.flag == 0){
            // デッキの残り枚数をデクリメント
            let text = $("#js-deck").text();
            text     = text.match(/\d{1,3}/);
            let textNumber = Number(text) - 1;
            $("#js-deck").html('あと' + textNumber + '枚');

            // 観戦用処理
            if (chatChannel.isWatch()) {
              chatChannel.logWatch(`<span class="player-name">${data.playing_user_name}</span>さんがデッキから<span class="card-name">${data.draw_card.name}</span>を引きました`);
              chatChannel.drawWatchPlayerCard(data.playing_user_id, data.draw_card.id, data.draw_card.name);
            }

            // 現在ターンのユーザーの判定
            if(data.playing_user_number == $("#js-hand").data("play_order")){
              // 引いたカードを追加
              let node       = document.createElement("div");
              node.className = 'sample3';
              node.innerHTML = '<div class="my-card">' + `<button class="delete" data-play_card_id="${data.draw_card.id}" style="pointer-events: auto;">` + data.draw_card.name + '<div class="mask3">' + '<div class="caption3">カードをクリックして場に捨ててください</div>' + `</div></button></div>`;
              document.getElementById("js-hand").appendChild(node);

              // オーバーレイの表示変更
              $('.mask').remove();
              $('.mask2').each(function() {
                $(this).remove();
              });
              $('.delete').each(function() {
                $(this).append(`<div class="mask3"><div class="caption3">カードをクリックして場に捨ててください</div></div>`);
              });

            }
            // デッキからカードを引くことをできなくさせる
            $(".js-draw").css('pointer-events', 'none');
          } // デッキからカードを引いた時の処理　終了

          // フィールドからカードが引かれた時の処理
          if(data.flag == 1){
            if(data.playing_user_number == $("#js-hand").data("play_order")){
              let node       = document.createElement("div");
              node.className = 'sample3';
              node.innerHTML = '<div class="my-card">' + `<button class="delete" data-play_card_id="${data.draw_card.id}" style="pointer-events: auto;">` + data.draw_card.name + '<div class="mask3">' + '<div class="caption3">カードをクリックして場に捨ててください</div>' + `</div></button></div>`;
              document.getElementById("js-hand").appendChild(node);
              $(".js-draw").css('pointer-events', 'none');

              // オーバーレイの表示変更
              $('.mask').remove();
              $('.mask2').each(function() {
                $(this).remove();
              });
              $('.delete').each(function() {
                $(this).append(`<div class="mask3"><div class="caption3">カードをクリックして場に捨ててください</div></div>`);
              });
            }
            // フィールドからカードが引かれた時の削除処理
            $("#js-field").find(`[data-play_card_id=${data.draw_card.id}]`).parent().remove();
            // 観戦時
            if (chatChannel.isWatch()) {
              chatChannel.logWatch(`<span class="player-name">${data.playing_user_name}</span>さんがフィールドから<span class="card-name">${data.draw_card.name}</span>を引きました`);
              chatChannel.drawWatchPlayerCard(data.playing_user_id, data.draw_card.id, data.draw_card.name);
            }
          } // フィールドからカードを引いた時の処理 終了
          // 「カードを捨てる」ボタンを押せるようにする
          if(data.playing_user_number == $("#js-hand").data("play_order")){
            $(".delete").css('pointer-events', 'auto');
          }// フィールドからカードが引かれた時の処理 終了
        }// カードを引いた時の処理 終了


        // カードを捨てた時の処理
        if(data.other_card != null){
          // 手札のカードをOtherに追加する処理
          let node       = document.createElement("div");
          node.className = "sample2 card-detail";
          node.innerHTML = `<button class="js-draw btn-draw button-card" data-play_card_id=${data.other_card.id} style="pointer-events: none;">` + data.other_card.name + '<div class="mask2">' + '<div class="caption2">デッキか場からカードをクリックして手札に加えてください</div>' +`</div></button>`;
          document.getElementById("field-flex").appendChild(node);

          // オーバーレイの表示変更
          $('.mask').remove();
          $('.mask2').each(function() {
            $(this).remove();
          });
          $('.mask3').each(function() {
            $(this).remove();
          });

          // ターンの交代処理
          $("#now_player").html(data.playing_user_name + "さんの番です");
          $("#switch").css('pointer-events', 'none');
          $(".js-draw").css('pointer-events', 'none');
          $(".delete").css('pointer-events', 'none');
          $("#end").css('pointer-events', 'none');
          $(".my-card").find(`[data-play_card_id=${data.other_card.id}]`).parent().remove();

          let playOrder = Number($("#js-hand").attr("data-play_order"));
          // 自分のターンの人はリロード
          if(playOrder == data.playing_user_number){
            location.reload();
          }

          // 観戦時
          if (chatChannel.isWatch()) {
            chatChannel.setWatchActivePlayer(data.playing_user_id);
            chatChannel.dropWatchPlayerCard(data.action_user_id, data.other_card.id);
            chatChannel.logWatch(`<span class="player-name">${data.action_user_name}</span>さんが<span class="card-name">${data.other_card.name}</span>を捨てました`);
            chatChannel.logWatch(`<span class="turn-change">ターンチェンジ</span>：<span class="before-player-name">${data.action_user_name}</span>さん　→　<span class="player-name">${data.playing_user_name}</span>さん`);
          }
        } // カードを捨てた時の処理終了


        // プレイヤーの削除処理
        if(data.delete_player != null){
          // メンバー一覧から削除
          $(`.join_player[data-user_id=${data.delete_player}]`).remove();
          // メンバー一覧番号振り直し
          $('.join_player').each(function (index) {
            $(this).find('.player_number').text(index + 1);
          });

          if(data.delete_play_order < $('#js-hand').data('play_order')){
            $("#js-hand")[0].dataset.play_order = $('#js-hand').data('play_order') - 1;
          }

          // 削除されたプレイヤーのホーム画面へのリダイレクト
          if (chatChannel.getCurrentUserId() == data.delete_player) {
            document.location.assign('/');
          }

          // 観戦時
          if (chatChannel.isWatch()) {
            chatChannel.deleteWatchPlayer(data.delete_user.id);
            if (data.is_exit) {
              chatChannel.logWatch(`<span class="alert-thing">${data.delete_user.username}</span>さんが<span class="alert-thing">退室</span>しました`);
            }
            else {
              chatChannel.logWatch(`<span class="player-name">${data.action_user.username}</span>さんが<span class="alert-thing">${data.delete_user.username}</span>さんを<span class="alert-thing">削除</span>しました`);
            }
          }

          // ターンが変わった場合
          if (data.is_turn_player_change) {
            // 自分のターンの人はリロード
            if(chatChannel.getCurrentUserId() == data.next_turn_user.id){
              location.reload();
            }

            $("#now_player").html(data.next_turn_user.username + "さんの番です");

            // 観戦時
            if (chatChannel.isWatch()) {
              chatChannel.setWatchActivePlayer(data.next_turn_user.id);
              chatChannel.logWatch(`<span class="turn-change">ターンチェンジ</span>：<span class="before-player-name">${data.delete_user.username}</span>さん　→　<span class="player-name">${data.next_turn_user.username}</span>さん`);
            }
          }
        }

        // 終了処理
        if(data.end != null){
          let url        = location.href;
          var roomNumber = url.match(/rooms\/\d*/);
          roomNumber     = Number(roomNumber[0].slice(6));

          if(roomNumber == data.end.id){
            document.location.assign('./result');
          }
        }

        // 例外処理
        if(data.exception_catch != null){
            document.location.assign('/');
        }

      }, // received関数終了


      draw: function(user_id, room_id, play_card_id) {
        // console.log("jsのdrawに到達");
        return this.perform('draw', {
          user_id: user_id, room_id: room_id, play_card_id: play_card_id
        });
      }, // draw関数終了


      delete: function(user_id, play_card_id){
        // console.log("jsのdelete関数に到達");
        return this.perform('delete', {
          user_id: user_id,
          play_card_id: play_card_id
        });
      }, // delete関数終了

      playerDelete: function(current_user_id, user_id){
        return this.perform('player_delete', {
          user_id: user_id, current_user_id: current_user_id
        });
      }, // playerDelete関数終了

      // 観戦中かどうか
      isWatch: function () {
        return $('.room_watch').length > 0;
      },
      // 観戦時のプレイヤーカードドロー
      drawWatchPlayerCard: function (user_id, card_id, card_name) {
        let node = $(`<div class="player-card player_card" data-play_card_id="${card_id}"/>`);
        node.text(card_name);
        $('.room_watch').find(`.player_detail[data-player_id="${user_id}"]`).find('.player_cards').append(node);
      },
      // 観戦時のプレイヤーカードドロップ
      dropWatchPlayerCard: function (user_id, card_id) {
        console.log('drop', user_id, card_id);
        $('.room_watch').find(`.player_detail[data-player_id="${user_id}"]`).find('.player_cards').find(`.player_card[data-play_card_id="${card_id}"]`).remove();
      },
      // 観戦時のアクティブプレイヤー変更
      setWatchActivePlayer: function (user_id) {
        console.log('change_active', user_id);
        $('.room_watch').find(".player_detail").removeClass('active-player');
        $('.room_watch').find(`.player_detail[data-player_id="${user_id}"]`).addClass('active-player');
      },
      // 観戦時のプレイヤー削除
      deleteWatchPlayer: function (user_id) {
        $('.room_watch').find(`.player_detail[data-player_id="${user_id}]"`).remove();
      },
      // 観戦ログ
      logWatch: function (log_content_html) {
        let node = $('<div class="history-detail"/>');
        let current_date = new Date();
        node.append($(`<div class="time">${current_date.getHours().toString().padStart(2, "0")}:${current_date.getMinutes().toString().padStart(2, "0")}:${current_date.getSeconds().toString().padStart(2, "0")}</div>`));
        node.append(log_content_html);
        // 先頭に追加
        $('.room_watch').find('.history_details').prepend(node);
        // スクロールバーを戻す
        $('.room_watch').find('.window_history').find('.simplebar-content-wrapper').scrollTop(0);
      },
    }); // chatChannelの定義終了


    // デッキorフィールドのボタンを押した時
    $('.js-draw').on('click', function(){
      chatChannel.draw($(this).data('user_id'), $(this).data('room_id'), $(this).data('play_card_id'));
    });

    // 手札の削除ボタンを押した時
    $('body').on('click', '.delete', function() {
      let current_user_id = chatChannel.getCurrentUserId();
      let target_play_card_id = $(this).data('play_card_id');

      chatChannel.delete(current_user_id, target_play_card_id);

      $(this).parent().parent().remove();
    });

    // プレイヤー削除ボタンを押した時
    $('body').on('click', '.player_delete', function(){
      let current_user_id = chatChannel.getCurrentUserId();
      let target_user_id = $(this).data('user_id');

      chatChannel.playerDelete(current_user_id, target_user_id);
    });

    $(function() {
      history.pushState(null, null, null);

      $(window).on("popstate", function(){
        history.pushState(null, null, null);
      });
    });

  }
});

